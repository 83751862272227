import { UserType } from '@les-sherpas/sherpas-toolbox';
import { ParsedUrlQuery } from 'querystring';

import { AuthenticationType } from '@/components/Authentication/Authentication.utils';

const shouldNotRedirect = (query: ParsedUrlQuery) =>
  !!query.redirect && query.redirect === 'false';

const getOnboardingRedirectionUrl = (
  authentication: AuthenticationType,
  query: ParsedUrlQuery
): string | undefined => {
  const { redirectUrl } = query;

  /** partie étudiant */
  const isLoggedInAsStudent =
    authentication?.isAuthenticated &&
    authentication.authData.userType === UserType.Student;
  const isLoggedInAsTeacher =
    authentication?.isAuthenticated &&
    authentication.authData.userType === UserType.Teacher;
  const isLoggedIn = isLoggedInAsStudent || isLoggedInAsTeacher;
  const isStudentProfileComplete =
    authentication?.authData?.address && authentication?.authData?.phoneNumber;
  /** ************************* */

  /** partie professeur */
  const isEmailVerified = authentication?.authData.isEmailVerified;
  const isTeacherWithStripeAccount =
    !authentication?.teacherInfo?.shouldBeOnboard;
  const isTeacherWithDraftInProgress =
    authentication?.teacherInfo.hasDraftInProgress;
  const isTeacherWithAnnounce =
    authentication?.authData.isEmailVerified &&
    authentication?.teacherInfo?.hasAnnounce;
  const isTeacherWithTrainingAppointmentClicked =
    authentication?.teacherInfo?.hasClickedOnTrainingAppointment;
  const isTeacherProfileComplete =
    isTeacherWithStripeAccount &&
    isTeacherWithAnnounce &&
    isEmailVerified &&
    isTeacherWithTrainingAppointmentClicked;
  /** ************************* */

  if (shouldNotRedirect(query)) {
    return undefined;
  }
  /** partie non connecté */

  if (!isLoggedIn) {
    return undefined;
  }

  /** partie étudiant */

  if (isLoggedInAsStudent && !isStudentProfileComplete) {
    return `/onboarding-student`;
  }

  if (isLoggedInAsStudent && redirectUrl) {
    return `${redirectUrl}`;
  }

  if (
    isLoggedInAsStudent &&
    isStudentProfileComplete &&
    query.method === 'login' &&
    !redirectUrl
  ) {
    return '/professeurs';
  }

  /** partie professeur */

  if (isLoggedInAsTeacher && !isEmailVerified) {
    return `/sherpa/signup?step=5`;
  }

  if (isLoggedInAsTeacher && !isTeacherWithStripeAccount) {
    return `/sherpa/register?onBoard=true`;
  }

  if (
    isLoggedInAsTeacher &&
    isTeacherWithDraftInProgress &&
    !isTeacherWithAnnounce
  ) {
    return `/sherpa/create-announce?id=${authentication.teacherInfo.draftId}`;
  }

  if (
    isLoggedInAsTeacher &&
    (!isTeacherWithAnnounce || !isTeacherWithTrainingAppointmentClicked)
  ) {
    return `/sherpa/register`;
  }

  if (
    isLoggedInAsTeacher &&
    isTeacherProfileComplete &&
    query.method === 'login'
  ) {
    return '/tableau-de-bord';
  }

  return undefined;
};

export default getOnboardingRedirectionUrl;
