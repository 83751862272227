import { useEffect, useState } from 'react';
import { useRouter } from 'next/router';

import { AuthenticationType } from '@/components/Authentication/Authentication.utils';
import getOnboardingRedirectionUrl from '@/components/Authentication/OnboardingRedirection.utils';

const useOnboardingRedirection = ({
  authentication,
  origin,
}: {
  authentication: AuthenticationType;
  origin?: string;
}) => {
  const { push, query } = useRouter();
  const [isOnboardingRedirectionEnd, setIsOnboardingRedirectionEnd] =
    useState(false);

  useEffect(() => {
    const { authData } = authentication || {};
    if (authData) {
      const redirectionUrl = getOnboardingRedirectionUrl(authentication, query);
      const redirectionWithoutParams = redirectionUrl?.split('?')[0];
      if (redirectionUrl && origin !== redirectionWithoutParams) {
        push(redirectionUrl);
      } else {
        setIsOnboardingRedirectionEnd(true);
      }
    } else {
      setIsOnboardingRedirectionEnd(!authData);
    }
  }, [authentication, origin, push, query]);

  return { isOnboardingRedirectionEnd };
};

export default useOnboardingRedirection;
