import { FC } from 'react';
import { useIntl } from 'react-intl';

import {
  LYON_LINKS,
  NICE_LINKS,
  PARIS_LINKS,
  TOULOUSE_LINKS,
} from '@/components/Landing/Seo/Seo.utils';
import SeoHeader from '@/components/LandingMozza/common/SeoHeader/SeoHeader';
import SeoTable from '@/components/LandingMozza/common/SeoTable/SeoTable';
import {
  CITIES_LIST,
  LEVELS_LIST,
  SUBJECTS_LIST,
} from '@/components/LandingMozza/LandingMozza/SeoLinksLandingMozza/constants';

import messages from './messages';

import useStyles from './styles';

const SeoLinksLandingMozza: FC = () => {
  const { formatMessage } = useIntl();
  const { classes } = useStyles();
  return (
    <>
      <SeoHeader />
      <SeoTable
        links={SUBJECTS_LIST}
        title={formatMessage(messages.titleSubject)}
        type="subject"
      />
      <SeoTable
        links={LEVELS_LIST}
        title={formatMessage(messages.titleLevel)}
        type="subject"
      />
      <SeoTable
        links={CITIES_LIST}
        title={formatMessage(messages.titleCities)}
        type="location"
      />
      <div className={classes.grid}>
        <SeoTable
          links={PARIS_LINKS}
          title="Paris"
          key="subject-paris"
          type="subject"
          isOneColumn
        />
        <SeoTable
          links={TOULOUSE_LINKS}
          title="Toulouse"
          key="subject-toulouse"
          type="subject"
          isOneColumn
        />
        <SeoTable
          links={NICE_LINKS}
          title="Nice"
          key="subject-nice"
          type="subject"
          isOneColumn
        />
        <SeoTable
          links={LYON_LINKS}
          title="Lyon"
          key="subject-lyon"
          type="subject"
          isOneColumn
        />
      </div>
    </>
  );
};

export default SeoLinksLandingMozza;
