import rem from 'polished/lib/helpers/rem';
import { makeStyles } from 'tss-react/mui';

export default makeStyles()(({ breakpoints }) => ({
  grid: {
    display: 'grid',
    gap: rem(16),
    width: '100%',
    gridTemplateColumns: `repeat(2, 1fr)`,
    [breakpoints.up('md')]: {
      gridTemplateColumns: `repeat(auto-fit, minmax(200px, 1fr))`,
      gap: rem(24),
    },
  },
}));
